import dayjs from "src/lib/dayjs";
import { AuditFilter, RangeFilterParameter, RecordingsFilter, SearchFilterParameter } from "src/types/Filter";
import logger from "../logger/logger";

export const filterToQueryString = (filter: RecordingsFilter | AuditFilter): string => {
  const { parameters, type } = filter;
  if (parameters.length === 0) {
    return "";
  }

  let parsedFrom, parsedTo;
  if (parameters[0].type === "range") {
    const param = parameters[0] as RangeFilterParameter;
    if (type === "timestamp") {
      parsedFrom = param.from ? dayjs.tz(param.from, param.timezone).utc().format() : "";
      parsedTo = param.to ? dayjs.tz(param.to, param.timezone).utc().format() : "";
    } else {
      parsedFrom = param.from ?? "";
      parsedTo = param.to ?? "";
    }
  }

  const params: string[] =
    parameters[0].type === "range"
      ? [encodeURIComponent(parsedFrom ?? ""), encodeURIComponent(parsedTo ?? "")]
      : (parameters as SearchFilterParameter[]).map(({ search }) => encodeURIComponent(search));
  return `${type}=${params.join(",")}`;
};
