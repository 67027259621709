import { Spin } from "antd";
import { FC } from "react";

const WelcomePage: FC = () => {
  return (
    <div style={{ paddingTop: "200px", textAlign: "center" }}>
      <Spin spinning={true} />
    </div>
  );
};

export default WelcomePage;
