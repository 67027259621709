import { FC } from "react";
import Layout from "antd/lib/layout";

import styled from "styled-components";
import "src/styles/index.less"; // Import Ant Design styles by less entry
import IconVfLogo from "./IconVfLogo";
import { medium, xlarge } from "src/utils/mediaQueries";
import useAppStore from "src/controller/app/store";
import { useLogout } from "src/services/auth/logout";
import Popover from "antd/lib/popover";
import Space from "antd/lib/space";
import Button from "antd/lib/button";
import { large } from "src/utils/mediaQueries";
import { UserAvatar } from "@carbon/icons-react";

const { Header } = Layout;

const HeaderStyled = styled(Header)`
  align-items: center;
  display: flex;
  height: 52px;
  padding: 0 10px;

  ${medium} {
    padding: 0 24px;
  }

  .anticon {
    margin-right: 0;
  }
`;

const DivDefaultLogoStyled = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 42px;
  margin-right: 10px;
  flex: 0 1 50px;
  ${xlarge} {
    flex: initial;
  }
`;

const IconVfLogoStyled = styled(IconVfLogo)`
  width: 50px;
`;

const DivNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
`;

const AppText = styled.span`
  color: white;
  font-size: 1.3em;
  margin-left: 16px;
`;

const DivPopoverContentStyled = styled.div`
  text-align: center;
`;

const ButtonUserStyled = styled(Button)`
  transform: scale(0.9);
  ${large} {
    transform: initial;
  }
`;

const AppHeader: FC = () => {
  const { isAuthenticated, userDetails } = useAppStore();
  const { logout } = useLogout();

  return (
    <HeaderStyled>
      <DivDefaultLogoStyled>
        <IconVfLogoStyled />
      </DivDefaultLogoStyled>
      <DivNavWrapper>
        <AppText>Historical Call Recordings</AppText>
        {/* <EnvironmentName environment={{ name: "DEV" }} /> */}
        {isAuthenticated && (
          <Popover
            content={
              <DivPopoverContentStyled>
                <h3>{userDetails.fullName.replace("|", " ")}</h3>
                <Space direction="vertical">
                  <Button onClick={logout}>Sign out</Button>
                </Space>
              </DivPopoverContentStyled>
            }
            trigger="click"
          >
            <ButtonUserStyled shape="circle">
              <UserAvatar size={24}/>
            </ButtonUserStyled>
          </Popover>
        )}
      </DivNavWrapper>
    </HeaderStyled>
  );
};

export default AppHeader;
