import Col from "antd/lib/grid/col";
import Row from "antd/lib/grid/row";
import Spin from "antd/lib/spin";
import { FC, memo } from "react";

export interface SuspenseFallbackProps {}

const SuspenseFallback: FC<SuspenseFallbackProps> = memo(() => {
  return (
    <Row align="middle" justify="center" style={{ height: "100%" }}>
      <Col>
        <Spin />
      </Col>
    </Row>
  );
});
SuspenseFallback.displayName = "SuspenseFallback";

export default SuspenseFallback;