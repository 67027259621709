import { RecordingsFilterMap } from "src/types/Filter";

const defaultRecordingsFilters: Record<"filters", RecordingsFilterMap> = {
  filters: {
    customerPhoneNumber: {
      type: "customerPhoneNumber",
      displayName: "Customer number",
      parameters: [],
      category: "default",
      intialPosition: 1,
    },
    systemPhoneNumber: {
      type: "systemPhoneNumber",
      displayName: "System number",
      parameters: [],
      category: "default",
      intialPosition: 2,
    },
    timestamp: {
      type: "timestamp",
      displayName: "Initiation time",
      parameters: [],
      category: "default",
      intialPosition: 3,
    },
    agent: {
      type: "agent",
      displayName: "Agent",
      parameters: [],
      category: "optional",
      intialPosition: 4,
    },
    direction: {
      type: "direction",
      displayName: "Direction",
      parameters: [],
      category: "optional",
      intialPosition: 5,
      options: ["inbound", "outbound"]
    },
    duration: {
      type: "duration",
      displayName: "Call duration",
      parameters: [],
      category: "optional",
      intialPosition: 6,
    },
  },
};

export default defaultRecordingsFilters;
