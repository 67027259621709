import { stringify } from "query-string";
import {
  cognitoAppClientId,
  cognitoClientDomain,
  cognitoAppClientSecret,
} from "src/config/config";

const refreshToken = async (): Promise<string> => {
  const appDomain = cognitoClientDomain;
  const appClientId = cognitoAppClientId;
  const appSecret = cognitoAppClientSecret;

  const requestBody = {
    grant_type: "refresh_token",
    client_id: appClientId,
    refresh_token: sessionStorage.getItem("refresh_token"),
  };

  const response = await fetch(`${appDomain}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(`${appClientId}:${appSecret}`)}`,
    },
    body: stringify(requestBody),
  }).then((response) => response.json());

  const { id_token, access_token } = response;
  sessionStorage.setItem("id_token", id_token);
  sessionStorage.setItem("access_token", access_token);

  return id_token;
};

export default refreshToken;
