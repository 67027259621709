import Axios from "axios";
import { apiEndpointControllers } from "src/config/config";
import getIdToken from "src/services/auth/getIdToken";
import refreshToken from "src/services/auth/refreshToken";
export type { AxiosRequestConfig, AxiosResponse } from "axios";

const axios = Axios.create({ baseURL: apiEndpointControllers });

axios.interceptors.request.use(async (config) => {
  config.headers = {
    ...config.headers,
    Authorization: `${(await getIdToken()) ?? ""}`,
  };

  return config;
});

axios.interceptors.response.use(
  (response) => ({
    ...response,
    data: "string" === typeof response.data && "" !== response.data ? JSON.parse(response.data) : response.data,
  }),
  async (error) => {
    if ([401, 403].includes(error.response.status) && !error.config._retry) {
      return axios({
        ...error.config,
        _retry: true,
        headers: {
          ...error.config.headers,
          Authorization: await refreshToken(),
        },
      });
    }

    return Promise.reject(error);
  },
);

export default axios;
