import { checkToken } from "./checkToken";

/**
 * Gets the id token of the current user out of session storage
 */
async function getIdToken(): Promise<string> {
  const tokenFromStorage = sessionStorage.getItem("id_token");
  if (!tokenFromStorage) {
    return "";
  }
  await checkToken(tokenFromStorage); // check token still valid
  return tokenFromStorage || "";
}

export default getIdToken;
