import { createContext, useContext, ReactNode } from "react";
import { UseRecordingResult, useRecordings } from "src/controller/recordings/useRecordings";

export const RecordingsContext = createContext<UseRecordingResult | undefined>(undefined);

interface Props {
    children: ReactNode
}
export const RecordingsProvider = ({ children } : Props) => {
  const recordingsObject = useRecordings();
  return <RecordingsContext.Provider value={recordingsObject}>{children}</RecordingsContext.Provider>;
};

export const useContextRecordings = () => {
  const value = useContext(RecordingsContext);
  if (value === undefined) {
    throw new Error("useContextConfig must be used within a ConfigProvider");
  }
  return value;
};
