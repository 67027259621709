import { IUser } from "src/types/User";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export type AppState = {
  userDetails: IUser;
  isAuthenticated: boolean;
  isAdminSession: boolean;
  setLogin: (userDetails: IUser, isAdmin: boolean) => void;
  setLogout: () => void;
};

const initialState = {
  userDetails: {
    id: "unknown",
    firstName: "",
    lastName: "",
    email: "",
    fullName: "anonymous|user",
    isAuthorized: false,
  },
  isAuthenticated: false,
  isAdminSession: false,
};

const useAppStore = create<AppState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setLogin: (userDetails, isAdmin): void => {
          set({ isAuthenticated: true, userDetails: userDetails, isAdminSession: isAdmin })
        },
        setLogout: (): void => set({ isAuthenticated: false, isAdminSession: false }),
      }),
      {
        name: "AppStore",
        getStorage: () => sessionStorage,
      },
    ),
  ),
);

export default useAppStore;
