import refreshToken from "./refreshToken";

export const checkToken = async (token: string): Promise<void> => {
  if (!token || !token.includes(".")) {
    return;
  }
  try {
    const tokenDataRaw = token.split(".")[1];
    const tokenDataObject = JSON.parse(atob(tokenDataRaw));
    const tokenExpiryDate = new Date(tokenDataObject.exp * 1000);
    if (new Date() > tokenExpiryDate) {
      await refreshToken();
    }
  } catch (error) {
    console.warn("Could not parse token", error);
  }
};
