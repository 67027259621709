import notification from "antd/lib/notification";
import message from "antd/lib/message";
import { TypeNotification } from "src/types/TypeNotification";
import { EnumNotificationType } from "src/common/constants/EnumNotificationType";
import { useCallback } from "react";

interface INotificationActions {
  successNotification: (text: string, title?: string) => Promise<void>;
  errorNotification: (text: string, title?: string) => Promise<void>;
  warnNotification: (text: string, title?: string) => Promise<void>;
  infoNotification: (text: string, title?: string) => Promise<void>;
  successMessage: (text: string) => Promise<void>;
  errorMessage: (text: string) => Promise<void>;
  warnMessage: (text: string) => Promise<void>;
  infoMessage: (text: string) => Promise<void>;
}

const useNotificationActions = (): INotificationActions => {
  const errorNotification = useCallback(
    async (text: string, title?: string): Promise<void> =>
      showNotification({
        style: "notification",
        type: EnumNotificationType.error,
        title: title || "Oops",
        body: text,
      }),
    [],
  );

  const infoNotification = async (text: string, title?: string): Promise<void> =>
    showNotification({
      style: "notification",
      type: EnumNotificationType.info,
      title: title || "Info",
      body: text,
    });

  const successNotification = async (text: string, title?: string): Promise<void> =>
    showNotification({
      style: "notification",
      type: EnumNotificationType.success,
      title: title || "Success",
      body: text,
    });

  const warnNotification = async (text: string, title?: string): Promise<void> =>
    showNotification({
      style: "notification",
      type: EnumNotificationType.warning,
      title: title || "Warning",
      body: text,
    });

  const infoMessage = async (text: string): Promise<void> =>
    showNotification({
      style: "message",
      type: EnumNotificationType.info,
      body: text,
    });

  const errorMessage = async (text: string): Promise<void> =>
    showNotification({
      style: "message",
      type: EnumNotificationType.error,
      body: text,
    });

  const warnMessage = async (text: string): Promise<void> =>
    showNotification({
      style: "message",
      type: EnumNotificationType.error,
      body: text,
    });

  const successMessage = async (text: string): Promise<void> =>
    showNotification({
      style: "message",
      type: EnumNotificationType.success,
      body: text,
    });

  const showNotification = (notificationDetails: TypeNotification) => {
    const isError = notificationDetails.type === EnumNotificationType.error;
    if (notificationDetails.style === "notification") {
      notification[notificationDetails.type]({
        message: notificationDetails.title,
        description: notificationDetails.body,
        placement: "topRight",
        duration: isError ? 0 : 4,
      });
    } else if (notificationDetails.style === "message") {
      message[notificationDetails.type](notificationDetails.body);
    }
  };

  return {
    successNotification,
    errorNotification,
    warnNotification,
    infoNotification,
    successMessage,
    errorMessage,
    infoMessage,
    warnMessage,
  };
};

export default useNotificationActions;
