import { RecordingsFilterMap, FilterParameter, TypeRecordingFilter } from "src/types/Filter";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import defaultRecordingsFilters from "src/common/constants/defaultRecordingsFilters";
import { cloneDeep } from "lodash";

export interface RecordingsFilterStore {
  filters: RecordingsFilterMap;
  setActive: (type: TypeRecordingFilter, active: boolean) => void;
  setFilters: (type: TypeRecordingFilter, parameters: FilterParameter[]) => void;
  removeFilter: (type: TypeRecordingFilter, id: string) => void;
  removeAllFilters: (type: TypeRecordingFilter) => void;
}

const useRecordingsFilterStore = create<RecordingsFilterStore>()(
  devtools(
    persist(
      (set) => ({
        ...defaultRecordingsFilters,
        setFilters: (type, parameters) =>
          set(({ filters }) => {
            const updating = cloneDeep(filters[type]);
            updating.parameters = parameters;
            if (!updating.setAt) {
              updating.setAt = Date.now();
            }
            return {
              filters: {
                ...filters,
                [type]: updating,
              },
            };
          }),
        removeFilter: (type: TypeRecordingFilter, id: string) =>
          set(({ filters }) => {
            const updating = cloneDeep(filters[type]);
            updating.parameters = updating?.parameters?.filter(({ id: fid }) => fid !== id);
            if (!updating?.parameters?.length) {
              updating.setAt = undefined;
            }
            return {
              filters: {
                ...filters,
                [type]: updating,
              },
            };
          }),
        removeAllFilters: (type: TypeRecordingFilter) =>
          set(({ filters }) => {
            const updating = cloneDeep(filters[type]);
            updating.parameters = [];
            updating.setAt = undefined;
            return {
              filters: {
                ...filters,
                [type]: updating,
              },
            };
          }),
        setActive: (type: TypeRecordingFilter, active: boolean) =>
          set(({ filters }) => {
            const updating = cloneDeep(filters[type]);
            if (updating.category === "default") {
              return { filters };
            }
            updating.setAt = active ? Date.now() : undefined;
            updating.parameters = active ? updating.parameters : []
            return {
              filters: {
                ...filters,
                [type]: updating,
              },
            };
          }),
      }),
      {
        name: "RecordingsFilterStoreStore",
        getStorage: () => sessionStorage,
      },
    ),
  ),
);

export default useRecordingsFilterStore;
