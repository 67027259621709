import axios, { AxiosResponse } from "axios";
import { stringify } from "query-string";
import { cognitoClientDomain, cognitoAppClientId, cognitoAppClientSecret } from "src/config/config";

const retrieveToken = async (code: string): Promise<AxiosResponse> => {
  const appDomain = cognitoClientDomain;
  const appClientId = cognitoAppClientId;
  const appSecret = cognitoAppClientSecret;

  const requestBody = {
    grant_type: "authorization_code",
    client_id: appClientId,
    code: code,
    redirect_uri: `${window.location.protocol}//${window.location.host}`,
  };
  const authString = `${appClientId}:${appSecret}`;
  const response = await axios(`${appDomain}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(authString)}`,
    },
    data: stringify(requestBody),
  });
  return response;
};

export default retrieveToken;
