import { useMemo } from "react";
import { RecordingsFilter, TypeRecordingFilter } from "src/types/Filter";
import useRecordingsFilterStore from "./store";

const useRecordingsFilters = () => {
  const { filters, setFilters, removeFilter, removeAllFilters, setActive } = useRecordingsFilterStore();

  const dynamicOrderedFilters: RecordingsFilter[] = useMemo(() => {
    const ordFilters = Object.keys(filters).map<RecordingsFilter>((k) => filters[k as TypeRecordingFilter]);
    return ordFilters.sort((a, b) => {
      return (a.setAt ?? Number.MAX_SAFE_INTEGER) - (b.setAt ?? Number.MAX_SAFE_INTEGER);
    });
  }, [filters]);

  const setOrderedFilters: RecordingsFilter[] = useMemo(() => {
    return Object.keys(filters).map<RecordingsFilter>((k) => filters[k as TypeRecordingFilter]);
  }, [filters]);

  return { dynamicOrderedFilters, setOrderedFilters, setFilters, removeFilter, removeAllFilters, setActive };
};

export default useRecordingsFilters;
