import dayjs from "src/lib/dayjs";
import { useEffect, useMemo } from "react";
import { useCallback, useState } from "react";
import axiosGet from "src/lib/axios/get";
import axiosPost from "src/lib/axios/post";
import { Recording } from "src/types/Recording";
import { TypeApiResponse } from "src/types/TypeApiResponse";
import useAppStore from "../app/store";
import useRecordingsFilters from "./filters/useRecordingsFilters";
import { useQueryRecordings } from "./query";
import JSZip from "jszip";
import axios from "axios";
import saveAs from "file-saver";
import { recordingsFormat } from "src/config/config";
import useNotificationActions from "../notification/actions";
export interface UseRecordingResult {
  recordings: Recording[];
  total: number;
  downloadMultipleRecordings: () => void;
  selectedForDownload: string[];
  setSelectedForDownload: (selected: string[]) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
  currPage: number;
  setCurrPage: (page: number) => void;
  isLoading: boolean;
  setOrderString: (order: string) => void;
  auditRecordingAction: (recordingId: string) => Promise<void>;
  downloadingMultiple: boolean;
}

export const useRecordings = (): UseRecordingResult => {
  const [selectedForDownload, setSelectedForDownload] = useState<string[]>([]);
  const [downloadingMultiple, setDowloadingMultiple] = useState(false);

  const { setOrderedFilters } = useRecordingsFilters();
  const {
    userDetails: { id },
  } = useAppStore();

  const filterParamIds = useMemo(() => {
    return setOrderedFilters.reduce<string[]>((accum, { parameters }) => {
      return [...accum, ...parameters.map(({ id }) => id)];
    }, []);
  }, [setOrderedFilters]);

  const [pageSize, setPageSize] = useState(25);
  const [currPage, setCurrPage] = useState(0);
  const [orderString, setOrderString] = useState("timestamp,desc");

  useEffect(() => {
    setCurrPage(0); // go back to first page on filter updates
  }, [filterParamIds]);

  const { data = { total: 0, recordings: [] }, isLoading } = useQueryRecordings(
    setOrderedFilters,
    filterParamIds,
    currPage,
    pageSize,
    orderString,
  );

  const { recordings, total } = useMemo(() => data, [data]);
  const { errorNotification } = useNotificationActions();

  const downloadMultipleRecordings = useCallback(async () => {
    setDowloadingMultiple(true);
    const dowloadingRecordingInfo = selectedForDownload.map((sid) => recordings.find(({id}) => id === sid));
    const qString = `?recordingIds=${selectedForDownload.map((id) => encodeURIComponent(id)).join(",")}`;
    const {
      data: { data: urls },
    } = await axiosGet<TypeApiResponse<string[]>>(`recordings/download${qString}`);
    const filename = `hcr export ${id} ${dayjs().format()}`;
    const zip = new JSZip();
    const folder = zip.folder(filename);


    urls.forEach((url, index) => {
      const data = axios<Blob>(url, {
        responseType: "blob",
      })
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
      folder?.file(`${dowloadingRecordingInfo[index]?.agent} ${dowloadingRecordingInfo[index]?.customerPhoneNumber} ${dowloadingRecordingInfo[index]?.timestamp}.${recordingsFormat}`, data);
    });

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => {
        saveAs(blob, `${filename}.zip`);
        setDowloadingMultiple(false);
      })
      .catch((error) => {
        errorNotification("Could not download recording");
        setDowloadingMultiple(false);
      });
  }, [selectedForDownload, id, setDowloadingMultiple, errorNotification, recordings]);

  const auditRecordingAction = useCallback(async (recordingId: string) => {
    try {
      await axiosPost(`/recording/log`, {
        recordingId,
        action: "played",
      });
    } catch (error) {
      console.log("ERROR auditRecordingAction", error);
      throw error;
    }
  }, []);

  return {
    recordings,
    total,
    downloadMultipleRecordings,
    selectedForDownload,
    setSelectedForDownload,
    pageSize,
    setPageSize,
    currPage,
    setCurrPage,
    isLoading,
    setOrderString,
    auditRecordingAction,
    downloadingMultiple,
  };
};
