import jwtDecode from "jwt-decode";

import { IUser, TCognitoIdTokenJson } from "src/types/User";

function getUserFromIdToken(idToken: string): IUser & {isAdmin: boolean} {
  const tokenJson = jwtDecode(idToken) as TCognitoIdTokenJson;
  const user: IUser & {isAdmin: boolean} = {
    id: tokenJson.email,
    email: tokenJson.email,
    firstName: tokenJson.given_name,
    lastName: tokenJson.family_name,
    fullName: `${tokenJson.given_name}|${tokenJson.family_name}`,
    isAuthorized: tokenJson.isAuthorized,
    isAdmin: tokenJson["custom:isAdmin"]
  };
  return user;
}

export default getUserFromIdToken;
