import { useMutation, useQuery } from "@tanstack/react-query";
import axiosGet from "src/lib/axios/get";
import { RecordingsFilter } from "src/types/Filter";
import { Recording, RecordingAPIResult } from "src/types/Recording";
import { TypeApiResponse } from "src/types/TypeApiResponse";
import { filterToQueryString } from "src/utils/filters/filterToQueryString";
import JSZip from "jszip";
import axios from "axios";
import saveAs from "file-saver";
import { recordingsFormat } from "src/config/config";
import useNotificationActions from "../notification/actions";
import logger from "src/utils/logger/logger";
interface SignedUrlPayload {
  url: string;
}

export const useQueryRecordings = (
  filters: RecordingsFilter[],
  paramIds: string[],
  page: number,
  pageSize: number,
  orderString: string,
) => {
  const { errorNotification } = useNotificationActions();

  return useQuery<RecordingAPIResult | undefined>(["recordings", page, pageSize, orderString, ...paramIds], async () => {
    const qString = filters
      .map((f) => filterToQueryString(f))
      .filter((s) => s !== "")
      .join("&");

    const [orderBy, orderDirection] = orderString.split(",");

    try {
      const {
        data: { data },
      } = await axiosGet<TypeApiResponse<RecordingAPIResult>>(
        `/recordings?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&orderDirection=${orderDirection}${
          qString.length ? `&${qString}` : ""
        }`
      );
      logger.info(data);
      return data;
    } catch (error) {
      errorNotification("Could not retreive recordings!");
      logger.error("ERROR useQueryRecordings", error);
      return { total: 0, recordings: [] };
    }
  });
};

export const useQueryPlayback = (id: string) =>
  useQuery<string>(["playback", id], async () => {
    const { data } = await axiosGet<TypeApiResponse<SignedUrlPayload>>(`/recordings/playback/${id}`);
    const {
      data: { url },
    } = data;
    return url;
  });

export const useMutationDownloadRecording = () => {
  const { errorNotification } = useNotificationActions();

  return useMutation(
    async (recording: Recording) => {
      const { id, agent, timestamp, customerPhoneNumber } = recording;
      const filename = `${agent} ${customerPhoneNumber} ${timestamp}.${recordingsFormat}`;
      const {
        data: { data: urls },
      } = await axiosGet<TypeApiResponse<string[]>>(`recordings/download?recordingIds=${id}`);
      const [url] = urls;

      const data = axios<Blob>(url, {
        responseType: "blob",
      })
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });

      const zip = new JSZip();
      const folder = zip.folder(id);
      folder?.file(filename, data);
      await zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, `${filename}.zip`);
        })
        .catch((error) => {
          throw error;
        });
    },
    {
      onError: () => {
        errorNotification("Download failed!");
      },
    },
  );
};
