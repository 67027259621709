/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from "axios";
import getIdToken from "src/services/auth/getIdToken";
import axios from "./axios";

async function axiosPost<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  const headers = {
    ...(config?.headers ?? {}),
    Authorization: await getIdToken(),
  };
  const response = await axios.post(url, data, {
    ...(config ?? {}),
    headers,
  });
  return {
    ...response,
    data: typeof response.data === "string" && response.data !== "" ? JSON.parse(response.data) : response.data,
  };
}

export default axiosPost;
