import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAppStore from "src/controller/app/store";

interface IProtectedRouteProps {
  redirectPath?: string;
  children?: any;
}

const ProtectedRoute = ({ redirectPath = "/login", children }: IProtectedRouteProps) => {
  const { userDetails, isAdminSession } = useAppStore();
  if (!userDetails.isAuthorized) {
    if (!isAdminSession) return <Navigate to={redirectPath} replace />;

    return <Navigate to={"/admin"} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
