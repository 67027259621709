import { Warning } from "@carbon/icons-react";
import { Button, Space } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Title from "src/common/Title";
import styled from "styled-components";

const DivContainerStyled = styled(Space)`
  background: #eeeeee;
  height: 100vh;
  width: 100vw;
  justifycontent: center;
  position: fixed;
  z-index: 1;
`;

const ContentStyled = styled(Space)`
  text-align: center;
  width: 100vw;
  top: calc(100vw+100px) / 2;
`;

const TitleStyled = styled.div`
  color: var(--vf-primary-color);

`;

const WarningStyled = styled(Warning)`
  color: var(--vf-primary-color);
`

const LoggedOut = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // clear storage
    sessionStorage.clear();
  }, []);

  return (
    <DivContainerStyled>
      <ContentStyled direction="vertical">
        <Title level={5}>
          <Space size="small" direction="horizontal">
          <WarningStyled size={20}/><TitleStyled>You have been signed out.</TitleStyled>
          </Space>
        </Title>
        <p>Please sign in again to continue using this application.</p>
        <p>
          <Button type="primary" onClick={() => navigate("/login")}>
            Sign In
          </Button>
        </p>
      </ContentStyled>
    </DivContainerStyled>
  );
};
export default LoggedOut;
