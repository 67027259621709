import { lazy } from "react";
import { IRoute } from "src/types/Route";

const Recordings = lazy(() => import("./pages/Recordings"));
const Audit = lazy(() => import("./pages/Audit"));

const routes: IRoute[] = [
  {
    Component: Recordings,
    path: "recordings",
    navIconType: "nav-recordings",
    navTitle: "Recordings",
    navOrder: 1,
    isProtected: true,
    isAdminRoute: false
  },
  {
    Component: Audit,
    path: "recordings/audit-trail",
    navIconType: "nav-recordings",
    navTitle: "Recordings",
    navOrder: 1,
    isProtected: true,
    isAdminRoute: true
  }
];

export default routes;
